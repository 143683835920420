import { createBrowserRouter } from 'react-router-dom';
import { lazy } from 'react';

const SubscribePage = lazy(() => import('components/subscribe'));
const Unsubscribe = lazy(() => import('components/unsubscribe'));

const enum EUrlPages {
  Subscribe = 'receiver-api/v1/subscribing/subscribe/:user_id/:domain_id/:signature/:email?',
  Unsubscribe = 'receiver-api/v1/unsubscribing/web-page/:email/:outcoming_id/:user_id/:domain_id/:sender_ipaddress/:signature',
  Not_Found = '*',
}

export const router = createBrowserRouter([
  {
    path: EUrlPages.Subscribe,
    async lazy() {
      return { Component: SubscribePage };
    },
  },
  {
    path: EUrlPages.Unsubscribe,
    async lazy() {
      return { Component: Unsubscribe };
    },
  },
  {
    path: EUrlPages.Not_Found,
    Component: null,
  },
]);
