import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { GlobalStyle, lightTheme } from '@bsg-core/components';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Loader from 'shared/components/Loader';

import { router } from './router/router';
import { Wrapper } from './styles';

const root = document.getElementById('root');

ReactDOM.render(
  <ThemeProvider theme={lightTheme}>
    <Suspense fallback={<Loader isCovering />}>
      <Wrapper>
        <RouterProvider router={router} />
      </Wrapper>
      <GlobalStyle />
    </Suspense>
  </ThemeProvider>,
  root,
);
